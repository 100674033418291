import { HomePage, TPPage, TPUsage, TPJobs, ProfilePage, TOSPage, PPPage, Billing } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tp',
        element: TPPage
    },
    {
        path: '/usage',
        element: TPUsage
    },
    {
        path: '/billing',
        element: Billing
    },
    {
        path: '/jobs',
        element: TPJobs
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/legal/tos',
        element: TOSPage
    },
    {
        path: '/legal/pp',
        element: PPPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
