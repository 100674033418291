import React, { useState, useEffect, useCallback, useRef } from 'react';
import S3Browser from '../../components/s3-browser/S3Browser'
import TPSettings from '../../components/tp-settings/TPSettings'
import TPResults from '../../components/tp-results/TPResults'
import TPSavedResults from '../../components/tp-saved-results/TPSavedResults'
import TPSavedResultsEditor from '../../components/tp-saved-results-editor/TPSavedResultsEditor'
import notify from 'devextreme/ui/notify';
import TPJobsTable from '../../components/tp-jobs-table/TPJobsTable'
import { useAuth } from '../../contexts/auth';
import { setS3CognitoUserCredentials, getResultByKey } from '../../api/s3'
import {getJobs} from '../../api/tpAPI'
import Accordion, {
    Item
} from 'devextreme-react/accordion';
import './tp.scss';

export default function TP() {

  const stepsAttributes = {
        id: 'steps-accordion-container',
        class: 'steps-accordion-container'
  }
  const { user, userCredentials, refreshSession } = useAuth();
  const tableRef = useRef();
  const accordionRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);
  const [generatedTP, setGeneratedTP] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [inProgressJob, setInProgressJob] = useState(null);
  const [refreshingJobs, setRefreshingJobs] = useState(false);
  const [savedTPState, setSavedTP] = useState([]);
  const [savedID, setSavedID] = useState(1);
  const [loading, setLoading] = useState(false);

  const onOpenFile = (e) => {
    console.log(e);
    setSelectedFile(e);
    if (!e) {
      setJobsData([]);
      setSelectedJob(null);
      setGeneratedTP([]);
      setRefreshingJobs(false);
    } else {
      accordionRef.current.instance.collapseItem(0);
      accordionRef.current.instance.expandItem(1);
      /*if (user.signInUserSession)  {
        setRefreshingJobs(true);
        getJobs(user, null, null, null, onGetJobsResultSuccess, onError);
      }*/
    }

  };

  const onTPRequested = () => {
    setTimeout(function() {
      setLoading(false);
      notify("Job created", 'success', 5000);
      getJobs(user, null, null, null, onGetJobsResultSuccess, onError);
      accordionRef.current.instance.collapseItem(1);
      accordionRef.current.instance.expandItem(2);
    }, 2000);

  }

  const onTPRequesting = () => {
    setLoading(true);
  }

  const onError = useCallback((error) => {
    console.log(error);
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onGetJobsResultSuccess = useCallback((data) => {
    console.log(selectedJob);
    const res = [];
    var rn = false;
    for (let i = 0; i<data.length; i++) {
      if (data[i].source_file === selectedFile.key) {
        data[i].key=data[i].results_file;
        res.push(data[i]);
        if (data[i].job_status === "In Progress") {
          if (!inProgressJob) {
            console.log("setting job in progress");
            console.log(data[i]);
            setInProgressJob(data[i]);
            setSelectedJob(data[i]);
          }
          rn = true;
        }
      }
    }
    setJobsData(res);
    if (rn) {
      setTimeout(function() {
        getJobs(user, null, null, null, onGetJobsResultSuccess, onError);
      }, 2000);
    } else {
      setRefreshingJobs(false);
      if (inProgressJob) {
        console.log("resetting job in progress");
        setSelectedJob(inProgressJob);
        getResultByKey(inProgressJob.results_file, onGetJobResultSuccess, onError);
        setInProgressJob(null);
      }
    }
  }, [inProgressJob, selectedJob, selectedFile]);

  const onJobClicked = (e) => {
    console.log(e);
    if (e.rowType === 'data') {
      setSelectedJob(e.data);
      if (e.data.job_status === 'Completed') {
        getResultByKey(e.data.results_file, onGetJobResultSuccess, onError);
      }
    } else {
      setGeneratedTP([]);
    }
  }

  const onSavedResults = (data) => {
    const res = [];
    for (let i = 0; i<data.length; i++) {
      let it = {...data[i]};
      it.id = savedID + i;
      res.push(it);
    }
    setSavedID(savedID + data.length);
    //setSavedTP([...savedTPState, ...res]);
    setSavedTP(res);
    accordionRef.current.instance.collapseItem(3);
    accordionRef.current.instance.expandItem(4);
  }



  const onGetJobResultSuccess = useCallback((data) => {
    console.log(data);
    if (!data.results) {
      setGeneratedTP([]);
      return;
    }
    const res=[];
    for (let i = 0; i<data.results.length; i++) {
      res.push({id:i, text:data.results[i]});
    }
    setGeneratedTP(res);
    accordionRef.current.instance.collapseItem(3);
    accordionRef.current.instance.expandItem(3);
  }, [])

  useEffect(() => {
    if (user.signInUserSession) {
      if (userCredentials !== undefined) {
        setS3CognitoUserCredentials(userCredentials, user.signInUserSession.idToken.payload.sub);
      }
    } else {
      refreshSession();
    }

  }, [userCredentials, user]);


  useEffect(() => {
    if ((!refreshingJobs) && (selectedFile) && (user.signInUserSession))  {
      setRefreshingJobs(true);
      getJobs(user, null, null, null, onGetJobsResultSuccess, onError);
    }
  }, [selectedFile, user.signInUserSession]);

  const selectionChanged = (e) => {
    //console.log(e);
    //console.log(accordionRef.current.instance.getDataSource());
  }

  const onSavedTPChanged = (data) => {
    setSavedTP(data);
  }

  const step1TitleRender = (data) => {
    if (selectedFile && selectedFile.name) {
      return (<>
          <h3 className="m-0">1. Pick a file</h3>
          <br/>
          <h4 className="m-0">{selectedFile.name}</h4>
        </>)
    }
    return (<h3 className="m-0">1. Pick a file</h3>)
  }

  const step3TitleRender = (data) => {
    if (selectedJob && selectedFile.name) {
      return (<>
          <h3 className="m-0">3. Jobs</h3>
          <br/>
          <h4 className="m-0">{new Date(selectedJob.created_at+"+00:00").toLocaleString()}<br/> {selectedJob.job_type}</h4>
        </>)
    }
    return (<h3 className="m-0">3. Jobs</h3>)
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Generate</h2>
      <Accordion
        ref={accordionRef}
        elementAttr={stepsAttributes}
        onSelectionChanged={selectionChanged}
        onItemClick={selectionChanged}
      >
        <Item
          titleRender={step1TitleRender}>
          <div>
            {userCredentials !== undefined && <S3Browser
              onOpenFile={onOpenFile}
              disabled={loading}
              api={require('../../api/s3')}
            />}
          </div>
        </Item>
        <Item
          disabled={selectedFile===null}
          titleRender={() => (<h3 className="m-0">2. Pick an output</h3>)}>
          <div>
            <TPSettings
              selectedFile={selectedFile}
              onTPRequested={onTPRequested}
              onTPRequesting={onTPRequesting}
            />
          </div>
        </Item>
        <Item disabled={jobsData.length===0} titleRender={step3TitleRender}>
          <div>
            <TPJobsTable ref={tableRef} dataSource={jobsData} onRowClicked={onJobClicked} hideFilename={true}/>
          </div>
        </Item>
        <Item disabled={generatedTP.length===0 || selectedJob.job_status !== "Completed"} titleRender={() => (<h3 className="m-0">4. Job Results</h3>)}>
          <div>
            {false && <TPResults generatedTP={generatedTP} jobData={selectedJob} onSaveResults={onSavedResults}/>}
            <TPSavedResultsEditor savedTP={generatedTP} jobData={selectedJob} onChange={onSavedTPChanged}/>
          </div>
        </Item>
        {false && <Item disabled={savedTPState.length===0} titleRender={() => (<h3 className="m-0">5. Saved Results</h3>)}>
          <div>
            {false && <TPSavedResults savedTP={savedTPState} jobData={selectedJob} onChange={onSavedTPChanged}/>}
            <TPSavedResultsEditor savedTP={savedTPState} jobData={selectedJob} onChange={onSavedTPChanged}/>

          </div>
        </Item>}
      </Accordion>
    </React.Fragment>
  )
}
