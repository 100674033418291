import React, { useEffect, useState, useCallback } from 'react';
import List, { ItemDragging } from "devextreme-react/list";
import notify from 'devextreme/ui/notify';
import HTMLDocGenerator from '../tp-docgenerator/HTMLDocGenerator';
import ReactHtmlParser from 'react-html-parser';
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { Button } from 'devextreme-react/button';

import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from 'devextreme-react/html-editor';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
const headerValues = [false, 1, 2, 3, 4, 5];
const fontSizeOptions = {
  inputAttr: {
    'aria-label': 'Font size',
  },
};
const fontFamilyOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};
const headerOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};

const TPSavedResultsEditor = props => {
    const docGenerator = new HTMLDocGenerator();

    const [selectedSaved, setSelectedSaved] = useState([]);
    const [outputFilename, setOutputFilename] = useState('QuickSum.docx');
    const [valueContent, setValueContent] = React.useState("");



    useEffect(() => {
      if (props.jobData) {
        var srcfilename = props.jobData.source_file.split('/').pop();
        srcfilename = srcfilename.split('.')
        srcfilename.pop()
        srcfilename = "QuickSum - " + srcfilename.join() + ".docx"
        setOutputFilename(srcfilename);
      }
    }, [props.jobData]);

    useEffect(() => {
      if (props.savedTP) {
        console.log(props.savedTP);
        console.log(props.jobData);
        var nv = "<br>\n";
        if (props.jobData.job_type.includes("alking") || props.jobData.job_type.includes("Social")) {
          nv=nv+"<ol>\n";
          for (let i=0; i<props.savedTP.length; i++) {
            nv=nv+"<li>"+props.savedTP[i].text.replace(/^\d\./g,"").trim()+"</li>\n";
          }
          nv=nv+"</ol>\n";
        } else {
          for (let i=0; i<props.savedTP.length; i++) {
            nv=nv+"<p>"+props.savedTP[i].text+"</p>\n";
          }
        }
        if (props.appendMode === true) {
          setValueContent(valueContent+nv);
        } else {
          setValueContent(nv);
        }

      }
    }, [props.savedTP]);



    const ListItem = (data) => {
      return (
          <div style={{textWrap :"wrap", whiteSpace: "break-spaces"}}>
              { data.text }
          </div>
      );
    };


    /*const onDownloadClick = () => {
      const doc = docGenerator.create(props.savedTP);
      Packer.toBlob(doc).then(blob => {
        //console.log(blob);
        saveAs(blob, outputFilename);
        console.log("Document created successfully");
      });
    }*/

    const onDownloadClick = (params) => {
      console.log(ReactHtmlParser(valueContent));
      const doc = docGenerator.create(ReactHtmlParser(valueContent));
      Packer.toBlob(doc).then(blob => {
        //console.log(blob);
        saveAs(blob, outputFilename);
        console.log("Document created successfully");
      });
    }

    const onDeleteClick = () => {
      const res = [];
      for (let i = 0; i<props.savedTP.length; i++) {
        var found = false;
        for (let j = 0; j<selectedSaved.length; j++) {
          if (props.savedTP[i].id === selectedSaved[j].id) {
            found = true;
          }
        }
        if (!found) {
          res.push(props.savedTP[i]);
        }
      }
      props.onChange(res);
      notify("Items deleted");
    };

    const valueChanged = useCallback((e) => {
      setValueContent(e.value);
      console.log(e.value)
    }, [setValueContent]);



    const onSavedSelectionChanged = (e) => {
      console.log(e.component._selection.options.selectedItems);
      setSelectedSaved(e.component._selection.options.selectedItems);
      //props.onSavedTPChanged(e.component._selection.options.selectedItems);
    };

/*
<Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
<Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
<Item name="separator" />
<Item name="bold" />
<Item name="italic" />
<Item name="strike" />
<Item name="underline" />
<Item name="separator" />
<Item name="alignLeft" />
<Item name="alignCenter" />
<Item name="alignRight" />
<Item name="alignJustify" />
<Item name="separator" />

<Item name="bulletList" />
<Item name="separator" />
<Item name="header" acceptedValues={headerValues} options={headerOptions} />
<Item name="separator" />
<Item name="color" />
<Item name="background" />
<Item name="separator" />
<Item name="link" />
<Item name="image" />
<Item name="separator" />
<Item name="clear" />
<Item name="codeBlock" />
<Item name="blockquote" />
<Item name="separator" />
*/

    return (
        <>


          {((props.savedTP.length) > 0) &&
            <>
              <HtmlEditor height="65vh"
                valueType="html"
                value={valueContent}
                onValueChanged={valueChanged}>
                <MediaResizing enabled={true} />
                <Toolbar multiline={true}>
                  <Item name="undo" />
                  <Item name="redo" />
                  <Item name="separator" />
                  <Item name="orderedList" />
                </Toolbar>
              </HtmlEditor>

                  <div className="h-100 mt-2">
                    <div className="d-none d-sm-flex flex-rox justify-content-between">
                      <Button
                        className="px-2 mb-3 ms-3"
                        icon="fas fa-download"
                        text="Download Results"
                        type="success"
                        size="lg"
                        disabled={props.savedTP.length === 0}
                        onClick={onDownloadClick}
                      />
                    </div>
                    <div className="d-flex flex-column d-sm-none">
                      <Button
                        className="mb-3"
                        icon="fas fa-download"
                        text="Download Results"
                        type="success"
                        size="lg"
                        disabled={props.savedTP.length === 0}
                        onClick={onDownloadClick}
                      />
                    </div>
                  </div>

            </>
          }
        </>
    );
};

export default TPSavedResultsEditor
