import React, { useState, useEffect, useCallback } from 'react';
import TPUsageChart from '../../components/tp-usage-chart/TPUsageChart'
import TPTotalUsageChart from '../../components/tp-total-usage-chart/TPTotalUsageChart'
import { Calendar } from 'devextreme-react/calendar';
import { Button } from 'devextreme-react/button';
import {getUsage} from '../../api/tpAPI'
import { useAuth } from '../../contexts/auth';
import Card from 'react-bootstrap/Card';

export default function Billing() {
  const { user, refreshSession } = useAuth();

  const [year, setYear] = useState((new Date()).getUTCFullYear());
  const [month, setMonth] = useState((new Date()).getUTCMonth()+1);
  const [usageData, setUsageData] = useState([]);
  const [totalUsageData, setTotalUsageData] = useState([]);
  const [maxAllowedUsage, setMaxAllowedUsage] = useState(0);
  const [calendarValue, setCalendarValue] = useState((new Date()));
  const [currentPlan, setCurrentPlan] = useState(null);

  const numDays = (y, m) => new Date(y, m, 0).getDate();

  const onCalendarValueChanged = (e) => {
    console.log(e.value);
    setCalendarValue(e.value);
    setYear(e.value.getUTCFullYear());
    setMonth(e.value.getUTCMonth()+1);
    getUsage(user, e.value.getUTCFullYear().toString(), (e.value.getUTCMonth()+1).toString(), '', onGetResultSuccess, onError);
  }

  const onError = (error) => {
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }

  const onGetResultSuccess = useCallback((result) => {
    const res = []
    for (let i=1; i<=numDays(year, month); i++) {
      res.push({day: (new Date(year, month, 0)).toLocaleString('default', { month: 'short' })+'-'+i.toString(), value: 0})
    }
    let total_count = 0;
    const data = result.usage;
    for (let i=0; i<data.length; i++) {
      const dd = new Date(data[i].created_at);
      res[dd.getDate()].value = res[dd.getDate()].value + data[i].page_count;
      total_count = total_count + data[i].page_count;
    }
    setUsageData(res);
    setMaxAllowedUsage(result.pages_included);
    setCurrentPlan(result.plan);
    setTotalUsageData([{label:'Used', value:total_count}, {label:'Remaining', value:maxAllowedUsage-total_count}])
  }, [maxAllowedUsage, month, year])

  useEffect(() => {

    if (user.signInUserSession) {
      console.log(user.signInUserSession.idToken.payload.sub);
      getUsage(user, year.toString(), month.toString(), '', onGetResultSuccess, onError);
    } else {
      refreshSession();
    }
  }, [user, year, month, onGetResultSuccess]);

  const onUpgrade = () => {

  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Billing</h2>

      <div className="d-none d-md-flex flex-row w-100 justify-content-start">
        <Card className="text-center m-3" style={{ width: '18rem' }}>
          <Card.Header><h2 className="p-0 m-0"><strong>Free</strong></h2></Card.Header>
          <Card.Body className="justify-content-center">
            <Card.Title>$0/month</Card.Title>
            {false && <Card.Subtitle className="mb-2 text-muted">$0/month</Card.Subtitle>}
            <Card.Text>
              Up to 500 pages/month
            </Card.Text>
          </Card.Body>
          {currentPlan ==="Free" && <Card.Footer>
            <Button
              type="normal"
              stylingMode="text">
              Current plan
            </Button>
          </Card.Footer>}
        </Card>
        <Card className="text-center m-3" style={{ width: '18rem' }}>
          <Card.Header><h2 className="p-0 m-0"><strong>QuikSum Personal</strong></h2></Card.Header>
          <Card.Body className="justify-content-center">
            <Card.Title>$20/month</Card.Title>
            {false && <Card.Subtitle className="mb-2 text-muted">$0/month</Card.Subtitle>}
            <Card.Text>
              Additional 2000 pages/month
            </Card.Text>
          </Card.Body>
          {currentPlan ==="QuikSum Personal" ?
          <Card.Footer>
            <a href={"https://billing.stripe.com/p/login/test_bIYcNk6Rb6Uxe8oaEE"} target='_'>
              <Button
                type="default"
                stylingMode="text">
                Manage plan
              </Button>
            </a>
          </Card.Footer>:
          <Card.Footer>
            <a href={"https://buy.stripe.com/test_4gw2aP7H5e4b0CIaEE?client_reference_id="+user.signInUserSession.idToken.payload.sub} target='_'>
              <Button
                className="px-5"
                type="default"
                text="Upgrade"
                size="lg"
              />
            </a>
          </Card.Footer>}
        </Card>
      </div>
      <div className="d-flex d-md-none flex-column w-100 justify-content-between">
        <Card className="text-center m-3" style={{ width: '18rem' }}>
          <Card.Header><h2 className="p-0 m-0"><strong>Free</strong></h2></Card.Header>
          <Card.Body className="justify-content-center">
            <Card.Title>$0/month</Card.Title>
            {false && <Card.Subtitle className="mb-2 text-muted">$0/month</Card.Subtitle>}
            <Card.Text>
              Up to 500 pages/month
            </Card.Text>
          </Card.Body>
          {currentPlan ==="Free" && <Card.Footer>
            <Button
              type="normal"
              stylingMode="text">
              Current plan
            </Button>
          </Card.Footer>}
        </Card>
        <Card className="text-center m-3" style={{ width: '18rem' }}>
          <Card.Header><h2 className="p-0 m-0"><strong>QuikSum Personal</strong></h2></Card.Header>
          <Card.Body className="justify-content-center">
            <Card.Title>$20/month</Card.Title>
            {false && <Card.Subtitle className="mb-2 text-muted">$0/month</Card.Subtitle>}
            <Card.Text>
              Additional 2000 pages/month
            </Card.Text>
          </Card.Body>
          {currentPlan ==="QuikSum Personal" ?
          <Card.Footer>
            <Button
              type="normal"
              stylingMode="text">
              Current plan
            </Button>
            <a href={"https://billing.stripe.com/p/login/test_bIYcNk6Rb6Uxe8oaEE"} target='_'>
              <Button
                type="default"
                stylingMode="text">
                Manage plan
              </Button>
            </a>
          </Card.Footer>:
          <Card.Footer>
            <a href={"https://buy.stripe.com/test_4gw2aP7H5e4b0CIaEE?client_reference_id="+user.signInUserSession.idToken.payload.sub} target='_'>
              <Button
                className="px-5"
                type="default"
                text="Upgrade"
                size="lg"
              />
            </a>
          </Card.Footer>}
        </Card>
      </div>



      <div className={'dx-card m-3'} style={{minWidth: "260px"}}>
        <TPTotalUsageChart
          totalUsageData={totalUsageData}
          maxAllowedUsage={maxAllowedUsage}
        />
      </div>

    </React.Fragment>
)}
