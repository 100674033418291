import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import {
  getUser,
  signIn as sendSignInRequest,
  signOut as sendSignOutRequest,
  signInNewAccount as sendSignInNewAccountRequest,
  refreshSession as sendRefreshSessionRequest
} from '../api/auth';
import AWS from 'aws-sdk';
import { setS3CognitoUserCredentials } from '../api/s3'

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [refreshToken, setRefreshToken] = useState(null);
  const [userCredentials, setUserCredentials] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.user);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (user !== undefined && user !== null && user.signInUserSession) {
      setRefreshToken(user.signInUserSession.getRefreshToken());
      let creds = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:907b7dc0-d1c7-4c06-9b05-7764956e7795',
        Logins: {
          'cognito-idp.us-west-2.amazonaws.com/us-west-2_KYn9KWwOq': user.signInUserSession.getIdToken().getJwtToken(),
        },
      });
      creds.get((err) => {
        if (err) {
          console.log(err);
          return;
        }
        setUserCredentials(creds);
        setS3CognitoUserCredentials(creds);
      });
    }
  }, [user]);

  useEffect(() => {
    if (user !== undefined && user !== null && user.signInUserSession === null) {
      refreshSession();
    }
  }, [user && user.signInUserSession]);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.user);
    }

    return result;
  }, []);

  const signInNewAccount = useCallback(async () => {
    const result = await sendSignInNewAccountRequest();
    if (result.isOk) {
      setUser(result.user);
    }

    return result;
  }, []);

  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if (result.isOk) {
      setUser(undefined);
    }
    return result;
  }, []);

  const refreshSession = useCallback(async () => {
    const result = await sendRefreshSessionRequest(refreshToken);
    if (result.isOk) {
      result.user.signInUserSession = result.session;
      setUser(result.user);
    } else {
      setUser(undefined);
    }
    return result;
  }, [refreshToken]);


  return (
    <AuthContext.Provider value={{ user, userCredentials, signIn, signInNewAccount, signOut, loading, refreshSession }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
