import {CognitoUser, AuthenticationDetails} from "amazon-cognito-identity-js";
import UserPool from "./UserPool";

var unconfirmedUser = null;
var unconfirmedUserCreds = {};
var resetPasswordUser = null;
var resetPasswordUserCreds = {};

export async function signIn(email, password) {

  return new Promise((resolve, reject) => {
    var authenticationData = {
    	Username: email,
    	Password: password,
    };

    var authenticationDetails = new AuthenticationDetails(authenticationData);
    var userData = {
    	Username: email,
    	Pool: UserPool,
    };
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(data) {
        resolve({
          isOk: true,
          user: cognitoUser,
          session: data,
        });
      },
      onFailure: function(err) {
        if (err.code==="UserNotConfirmedException") {
          unconfirmedUser = cognitoUser;
          unconfirmedUserCreds.email = email;
          unconfirmedUserCreds.password = password;
          reject({
            isOk: false,
            message: err.message,
            code: err.code
          });
          return;
        }

        reject({
          isOk: false,
          message: err.message
        });
      },
    });
  });
}

export async function refreshSession(token) {
  return new Promise((resolve, reject) => {
    let user = UserPool.getCurrentUser()
    user.refreshSession(token, function(err, session) {
      if (err) {
        reject({
          isOk: false,
          message: err.message
        });
        return;
      }
      resolve({
        isOk: true,
        session: session,
        user: UserPool.getCurrentUser()
      });
    });
  });
}

export async function signInNewAccount() {
  if (unconfirmedUserCreds.email) {
    return signIn(unconfirmedUserCreds.email, unconfirmedUserCreds.password);
  }
  if (resetPasswordUserCreds.email) {
    return signIn(resetPasswordUserCreds.email, resetPasswordUserCreds.password);
  }
  return;
}

export async function getUser() {
  var cognitoUser = UserPool.getCurrentUser();
  if (cognitoUser === null) {
    return {
      isOk: false
    };
  }
  await getUserToken(cognitoUser);
  if (cognitoUser.signInUserSession && cognitoUser.signInUserSession.isValid()) {
    return {
      isOk: true,
      user: cognitoUser
    };
  }
  return {
    isOk: false,
    user: cognitoUser
  };
}

function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function(err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export async function createAccount(email, password) {

    return new Promise((resolve, reject) => {
      const attributeList = [];
      UserPool.signUp(email, password, attributeList, null, (err, data) => {
        if (err) {
          reject({
            isOk: false,
            message: err.message
            //message: "Failed to create account"
          });
          return;
        }
        unconfirmedUser = data.user;
        unconfirmedUserCreds.email = email;
        unconfirmedUserCreds.password = password;
        resolve({
          isOk: true,
          user: data
        });
      });
    });
}

export async function confirmAccount(code) {
  return new Promise((resolve, reject) => {
    unconfirmedUser.confirmRegistration(code, true, (err, data) => {
      if (err) {
        reject({
          isOk: false,
          message: err.message
        });
        return;
      }
      resolve({
        isOk: true
      });
    });
  });
}

export async function resendConfirmationCode() {
  return new Promise((resolve, reject) => {
    unconfirmedUser.resendConfirmationCode((err, data) => {
      if (err) {
        reject({
          isOk: false,
          message: err.message
        });
        return;
      }
      resolve({
        isOk: true,
        message: data
      });
    });
  });
}

export async function signOut() {
  return new Promise((resolve, reject) => {
    UserPool.getCurrentUser().signOut((err, data) => {
      if (err) {
        reject({
          isOk: false,
          message: err.message
        });
        return;
      }
      resolve({
        isOk: true
      });
    });
  });
}

export async function changePassword(password, recoveryCode) {

  return new Promise((resolve, reject) => {
    resetPasswordUser.confirmPassword(recoveryCode, password, {
      onSuccess: function(data) {
        resetPasswordUserCreds.password = password;
        resolve({
          isOk: true,
        });
      },
      onFailure: function(err) {

        reject({
          isOk: false,
          message: err.message
        });
      },
    });
  });


}

export async function resetPassword(email) {
  return new Promise((resolve, reject) => {
    var userData = {
    	Username: email,
    	Pool: UserPool,
    };
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        resetPasswordUser = cognitoUser;
        resetPasswordUserCreds.email = email;
        resolve({
          isOk: true
        });
      },
      onFailure: function(err) {
        reject({
          isOk: false,
          message: err.message
        });
      },
    });
  });
}
