import {CognitoUserPool} from "amazon-cognito-identity-js";

 const poolData = {
     UserPoolId: "us-west-2_KYn9KWwOq",
     ClientId: "155ghp13j6gp8njmde7a8mr0ac",
     storage: window.localStorage
 }
const cognitoUserPool = new CognitoUserPool(poolData);

export default cognitoUserPool;
