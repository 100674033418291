import {getUser} from './auth'

export const getApiUrl = () => {
  return 'https://hhn5bx2j8e.execute-api.us-west-2.amazonaws.com/prod'
};

export const generate = async (user, type, query, onSuccess, onError) => {
  try {
    const response = await fetch(getApiUrl()+'/generate/'+type, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(query)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response, r);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const addFeedback = async (user, query, onSuccess, onError) => {
  try {
    const response = await fetch(getApiUrl()+'/feedback', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(query)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getUsage = async (user, year, month, day, onSuccess, onError, retries=3) => {
  try {
    let q = '/usage'
    if (year) {
      q = q + '?y=' + year
      if (month) {
        q = q + '&m=' + month
        if (day) {
          q = q + '&d=' + day
        }
      }
    }
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getJobs = async (user, year, month, day, onSuccess, onError, retries=3) => {
  try {
    let q = '/jobs'
    if (year) {
      q = q + '?y=' + year
      if (month) {
        q = q + '&m=' + month
        if (day) {
          q = q + '&d=' + day
        }
      }
    }
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.jobs);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}
