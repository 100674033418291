import React, { useEffect, useState } from 'react';
import { TextArea } from 'devextreme-react/text-area';
import { Slider, Tooltip } from 'devextreme-react/slider';
import { Button } from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import DropDownButton from 'devextreme-react/drop-down-button';
import {
    Form,
    SimpleItem,
    Label
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { generate } from '../../api/tpAPI'
import { useAuth } from '../../contexts/auth';

const TPSettings = props => {
  var { user, refreshSession } = useAuth();
  const jobTypes = [
    { id: 'talkingpoints', text: "Talking Points", icon: "fas fa-list" },
    { id: 'summary', text: "Summary", icon: "far fa-sticky-note" },
    { id: 'pr', text: "Press Release", icon: "far fa-newspaper" },
    { id: 'socialmedia', text: "Social Media", icon: "far fa-comments" },
    { id: 'coverletter', text: "Cover Letter", icon: "fas fa-envelope-open-text" },
    { id: 'coveremail', text: "Cover Email", icon: "fas fa-at" },
  ];
  const leaning = ['Left','Center','Right']
  const [loading, setLoading] = useState(false);
  const [tpRequestType, setTpRequestType] = useState(jobTypes[0]);
  const [tpRequestCount, setTpRequestCount] = useState(3);
  const [tpRequestIdeology, setTpRequestIdeology] = useState(leaning[1]);
  const [tpRequestTopic, setTpRequestTopic] = useState("");

  function format(value) {
    return `${value}`;
  }

  const onGenerate = () => {
    const data = {request_count: tpRequestCount, request_key: props.selectedFile.key}
    if (tpRequestIdeology === leaning[0]) {
      data.request_ideology = "democrat"
    }
    if (tpRequestIdeology === leaning[2]) {
      data.request_ideology = "republican"
    }
    if (tpRequestTopic.trim().length !== 0) {
      data.request_topic = tpRequestTopic.trim()
    }
    if (user.signInUserSession) {
      setLoading(true);
      generate(user, tpRequestType.id, data, onGenerateSuccess, onGenerateError);
      props.onTPRequesting();
    }
  };

  const onGenerateError = (error, body) => {
    setLoading(false);
    props.onTPRequested();
    if (error.status === 401) {
      refreshSession();
      return
    }
    if (error.status === 403) {
      notify(body.error, 'error', 5000);
      return
    }
    console.log(error);
  }

  const onGenerateSuccess = (data) => {
    console.log("success");
    setLoading(false);
    props.onTPRequested();
  }

  const handleTPRequestCountChange = (e) => {
    setTpRequestCount(e.value);
  }

  const handleTPRequestIdeologyChange = (e) => {
    setTpRequestIdeology(e.value);
  }

  const handleTPRequestTopicChange = (e) => {
    setTpRequestTopic(e.value);
  }

  const handleTPRequestJobTypeChange = (e) => {
    setTpRequestType(e.itemData);
  }

  useEffect(() => {
    if (!user.signInUserSession) {
      refreshSession();
    }

  }, [user && user.signInUserSession]);

  return (
    <>
      {props.selectedFile &&
      <div className="" style={{maxWidth: "500px"}}>

        <div className="form">
          <div className="dx-fieldset">
            <div className="dx-field">
              <div className="dx-field-label">
                Job type
              </div>
              <div className="dx-field-value">
               <DropDownButton
                 className="w-100"
                 dropDownOptions={{ width: 230 }}
                 useSelectMode={true}
                 selectedItemKey={tpRequestType.id}
                 items={jobTypes}
                 displayExpr="text"
                 keyExpr="id"
                 onItemClick={handleTPRequestJobTypeChange}
               />
              </div>
            </div>
            <div className="dx-field dx-field d-none d-sm-flex flex-row">
              <div className="dx-field-label">
                Topic of interest
              </div>
              <div className="dx-field-value">
                <TextArea
                    minHeight={100}
                    maxHeight={400}
                    autoResizeEnabled={true}
                    label="Topic of interest"
                    value={tpRequestTopic}
                    onValueChanged={handleTPRequestTopicChange}
                    disabled={loading}
                />
              </div>
            </div>

            <div className="dx-field d-flex flex-column d-sm-none">
              <TextArea
                  minHeight={100}
                  maxHeight={400}
                  autoResizeEnabled={true}
                  label="Topic of interest"
                  value={tpRequestTopic}
                  onValueChanged={handleTPRequestTopicChange}
                  disabled={loading}
              />
            </div>

            {tpRequestType.id === "talkingpoints" && <div className="dx-field custom-height-slider">
              <div className="dx-field-label">Number of talking points</div>
              <div className="dx-field-value">
                <Slider className="p-0 m-0" disabled={loading} min={1} max={10} value={tpRequestCount} onValueChanged={handleTPRequestCountChange}>
                  <Tooltip enabled={true} showMode="always" position="bottom" format={format} />
                </Slider>
              </div>
            </div>}
            {tpRequestType.id === "socialmedia" && <div className="dx-field custom-height-slider">
              <div className="dx-field-label">Number of messages</div>
              <div className="dx-field-value">
                <Slider disabled={loading} min={1} max={10} value={tpRequestCount} onValueChanged={handleTPRequestCountChange}>
                  <Tooltip enabled={true} showMode="always" position="bottom" format={format} />
                </Slider>
              </div>
            </div>}
            <div className="dx-field">
              <div className="dx-field-label">Ideology</div>
              <div className="dx-field-value">
                <RadioGroup className="mb-0 w-100" disabled={loading} items={leaning} value={tpRequestIdeology} layout="horizontal" onValueChanged={handleTPRequestIdeologyChange}/>
              </div>
            </div>
            <div className="dx-field d-flex flex-column d-sm-none">
              {loading && <LoadIndicator id="small-indicator" height={36} width={36} />}
              <Button
                className="mb-3"
                type="default"
                size="lg"
                icon="fas fa-feather-alt"
                text="Generate"
                disabled={loading}
                onClick={onGenerate}
              />
            </div>
            <div className="dx-field d-none d-sm-flex flex-row justify-content-center">
              {loading && <LoadIndicator id="small-indicator" height={36} width={36} />}
              <Button
                className="px-5 mb-3"
                type="default"
                size="lg"
                icon="fas fa-feather-alt"
                text="Generate"
                disabled={loading}
                onClick={onGenerate}
              />
            </div>
          </div>


        </div>

      </div>}

      {props.selectedFile === null &&
        <div className="dx-fieldset">
          <div className="dx-fieldset-header dx-theme-accent-as-text-color">Please select a document</div>
        </div>
      }

    </>
  );
};

export default TPSettings
