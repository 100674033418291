import React from 'react';
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
  Title,
  Font,
} from 'devextreme-react/chart';

const TPUsageChart = props => {

    return (
        <>
        <Chart

          palette="Violet"
          dataSource={props.data}
        >
          <Title text="Pages processed">
            <Font color="black" />
          </Title>
          <Series
            argumentField="day"
            valueField="value"
            type="bar"
          />

          <Margin bottom={20} />
          <ArgumentAxis
            allowDecimals={false}
            visible={true}
          >
            <Label alignment="left">
              <Format type="datetime" />
            </Label>
          </ArgumentAxis>
          <Legend
            visible={false}
          />
          <Tooltip enabled={true} />
        </Chart>
        </>
    );
};

export default TPUsageChart
