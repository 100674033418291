import React from 'react';
import FileManager, {
  Upload, Permissions, Toolbar, Item, FileSelectionItem,
} from 'devextreme-react/file-manager';
//import { uploadFileChunk, getItems, deleteItem, downloadItems, createDirectory } from '../../api/s3'
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import './S3Browser.scss';

const allowedFileExtensions = ['.docx', '.pdf'];

class S3Browser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null
    }

    this.fileManagerRef = React.createRef();

    this.openFileOption = {
        items: [
          {
            text: 'Open',
            icon: 'tags',
          },
        ],
        onItemClick: this.onItemClick.bind(this),

      };

    this.onItemClick = this.onItemClick.bind(this);
    this.onSelectedFileOpened = this.onSelectedFileOpened.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.fileSystemProvider = new CustomFileSystemProvider({
        createDirectory: props.api.createDirectory,
        deleteItem: props.api.deleteItem,
        downloadItems: props.api.downloadItems,
        getItems: props.api.getItems,
        uploadFileChunk: props.api.uploadFileChunk,
    });
    console.log(this);
  }

  render() {
    return (
      <FileManager
        height={"60vh"}
        ref={this.fileManagerRef}
        allowedFileExtensions={allowedFileExtensions}
        fileSystemProvider={this.fileSystemProvider}
        selectionMode="single"
        onContextMenuItemClick={this.onItemClick}
        onToolbarItemClick={this.onItemClick}
        onSelectedFileOpened={this.onSelectedFileOpened}
        onSelectionChanged={this.onSelectionChanged}
        disabled={this.props.disabled}
        customizeThumbnail={this.customizeIcon}
      >
        <Toolbar>
          <Item name="showNavPane" visible="true" />
          <Item name="separator" />
          <Item name="upload" icon="fas fa-upload"/>
          <Item name="refresh" icon="fas fa-sync"/>
          <Item name="separator" location="after" />
          <Item name="switchView" />

          <FileSelectionItem name="download" />
          <FileSelectionItem name="separator" />
          <FileSelectionItem name="delete" />

          <FileSelectionItem name="refresh" />
          {false&&<FileSelectionItem name="clearSelection" />}
        </Toolbar>
        <Permissions
          delete={true}
          upload={true}
          create={true}
          download={true}
        >
        </Permissions>
        <Upload
          chunkSize={6000000}
        />
      </FileManager>
    );
  }

  customizeIcon(fileManagerItem) {
    if (fileManagerItem.isDirectory)
        return 'far fa-folder';
    if (fileManagerItem.name.endsWith('.docx'))
        return 'far fa-file-word';
    if (fileManagerItem.name.endsWith('.pdf'))
        return 'far fa-file-pdf';
  }

  get fileManager() {
    return this.fileManagerRef.current.instance;
  }

  onItemClick({ itemData, viewArea, fileSystemItem }) {
    this.props.onOpenFile(this.state.selectedItem)
    let updated = false;

    if (itemData.extension) {
      updated = this.createFile(itemData.extension, fileSystemItem);
    } else if (itemData.category !== undefined) {
      updated = this.updateCategory(itemData.category, fileSystemItem, viewArea);
    }

    if (updated) {
      this.fileManager.refresh();
    }
  }

  onSelectedFileOpened(e) {
      this.props.onOpenFile(e.file)
  }

  onSelectionChanged(e) {

    console.log(e.selectedItems);
    if (e.selectedItems.length > 0) {
      if (e.selectedItems[0].isDirectory) {
        this.setState({
          selectedItem: null
        });
        this.props.onOpenFile(null);
      } else {
        this.props.onOpenFile(e.selectedItems[0]);
        this.setState({
          selectedItem: e.selectedItems[0]
        });
      }
    } else {
      this.setState({
        selectedItem: null
      });
    }
  }
}

export default S3Browser;
