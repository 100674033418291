import React from 'react';

import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  Selection,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Button } from 'devextreme-react/button';
import devices from 'devextreme/core/devices';
import './TPJobsTable.scss';

const pageSizes = [10, 25, 50, 100];

class TPJobsTable extends React.Component {
  constructor(props) {
    super(props);
    this.device = devices.current();
    console.log(this.device)
    this.state = {
      collapsed: false,
      focusedRowKey: undefined,
    };
    this.refreshDataGrid = this.refreshDataGrid.bind(this);
    this.onFocusedRowChanged = this.onFocusedRowChanged.bind(this);
  }

  dateToLocalTZ(data) {
    return new Date(data.created_at+"+00:00");
  }

  removeFilenamePrefix(data) {
    let fnp = data.source_file.split('/')
    fnp.shift();
    return fnp.join('/');
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;

    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option('focusedRowIndex', 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option('focusedRowIndex', rowsCount - 1);
        });
      }
    }
  }

  onFocusedRowChanged(e) {
    this.setState({
      focusedRowKey: e.component.option('focusedRowKey'),
    });
    this.props.onRowClicked(e.row);
  }

  jobTypeRender(data) {
    return (
      <>

          {data.data.job_type.includes("alking") && <Button
            icon="fas fa-list"
            text="Talking Points"
            type="normal"
            stylingMode="text"
          />}
          {data.data.job_type.includes("ummary") && <Button
            icon="far fa-sticky-note"
            text="Summary"
            type="normal"
            stylingMode="text"
          />}
          {data.data.job_type.includes("Press") && <Button
            icon="far fa-newspaper"
            text="Press Release"
            type="normal"
            stylingMode="text"
          />}
          {data.data.job_type.includes("Social") && <Button
            icon="far fa-comments"
            text="Social Media"
            type="normal"
            stylingMode="text"
          />}
          {data.data.job_type.includes("Cover Letter") && <Button
            icon="fas fa-envelope-open-text"
            text="Cover Letter"
            type="normal"
            stylingMode="text"
          />}
          {data.data.job_type.includes("Cover Email") && <Button
            icon="fas fa-at"
            text="Cover Email"
            type="normal"
            stylingMode="text"
          />}
      </>
    )
  }

  jobTypeRenderMobile(data) {
    return (
      <>
        {data.data.job_type.includes("alking") && <Button
          icon="fas fa-list"
          type="normal"
          stylingMode="text"
        />}
        {data.data.job_type.includes("ummary") && <Button
          icon="far fa-sticky-note"
          type="normal"
          stylingMode="text"
        />}
        {data.data.job_type.includes("Press") && <Button
          icon="far fa-newspaper"
          type="normal"
          stylingMode="text"
        />}
        {data.data.job_type.includes("Social") && <Button
          icon="far fa-comments"
          type="normal"
          stylingMode="text"
        />}
        {data.data.job_type.includes("Cover Lette") && <Button
          icon="fas fa-envelope-open-text"
          type="normal"
          stylingMode="text"
        />}
        {data.data.job_type.includes("Cover Email") && <Button
          icon="fas fa-at"
          type="normal"
          stylingMode="text"
        />}
      </>
    )
  }

  jobStatusRender(data) {
    return (
      <>
          {data.data.job_status.includes("Completed") && <Button
            icon="fas fa-check-circle"
            text="Completed"
            type="success"
            stylingMode="text"
          />}
          {data.data.job_status.includes("In Progress") && <>
          <LoadIndicator id="small-indicator" height={20} width={20} />
          <Button
            text="In Progress"
            type="normal"
            stylingMode="text"
          />
          </>}
          {data.data.job_status.includes("Failed") && <Button
            icon="fas fa-exclamation-triangle"
            text="Failed"
            type="danger"
            stylingMode="text"
          />}
      </>
    )
  }

  jobStatusRenderMobile(data) {
    return (
      <>
      {data.data.job_status.includes("Completed") && <Button
        icon="fas fa-check-circle"
        type="success"
        stylingMode="text"
      />}
      {data.data.job_status.includes("In Progress") && <>
      <LoadIndicator id="small-indicator" height={20} width={20} />

      </>}
      {data.data.job_status.includes("Failed") && <Button
        icon="fas fa-exclamation-triangle"
        type="danger"
        stylingMode="text"
      />}
      </>
    )
  }

  jobDateRenderMobile(data) {
    let dt = data.text.split(', ')
    return (
      <> {dt[0]}<br/>{dt[1]}
      </>
    )
  }

  render() {
    return (
      <>
      <div className="d-none d-sm-flex flex-row">
      <DataGrid
        ref={ref => this.dataGrid = ref}
        dataSource={this.props.dataSource}
        repaintChangesOnly={true}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        keyExpr="results_file"
        showBorders={true}
        width="100%"

        focusedRowEnabled={true}
        focusedRowKey={this.state.focusedRowKey}
        onFocusedRowChanging={this.onFocusedRowChanging}
        onFocusedRowChanged={this.onFocusedRowChanged}
        autoNavigateToFocusedRow={true}
      >
        <Selection mode="single" />
        {this.props.hideFilename? null: <Column dataField="source_file" dataType="string" groupIndex={0} calculateCellValue={this.removeFilenamePrefix}/>}
        <Column dataField="created_at" dataType="datetime" defaultSortOrder="desc" calculateCellValue={this.dateToLocalTZ} caption="Date"/>
        <Column dataField="job_type" dataType="string" caption="Type" cellRender={this.jobTypeRender} />
        <Column dataField="job_status" dataType="string" caption="Status" cellRender={this.jobStatusRender}/>
        <Column className="d-none d-sm-flex flex-row" dataField="job_message" dataType="string" />
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
      </div>
      <div className="d-flex flex-column d-sm-none">
      <DataGrid
        ref={ref => this.dataGrid = ref}
        dataSource={this.props.dataSource}
        repaintChangesOnly={true}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        keyExpr="results_file"
        showBorders={true}
        width="100%"

        focusedRowEnabled={true}
        focusedRowKey={this.state.focusedRowKey}
        onFocusedRowChanging={this.onFocusedRowChanging}
        onFocusedRowChanged={this.onFocusedRowChanged}
        autoNavigateToFocusedRow={true}
      >
        <Selection mode="single" />
        {this.props.hideFilename? null: <Column dataField="source_file" dataType="string" groupIndex={0} calculateCellValue={this.removeFilenamePrefix}/>}
        <Column dataField="created_at" dataType="datetime" defaultSortOrder="desc" cellRender={this.jobDateRenderMobile} calculateCellValue={this.dateToLocalTZ} caption="Date"/>
        <Column dataField="job_type" dataType="string" caption="Type" cellRender={this.jobTypeRenderMobile} width="68"/>
        <Column dataField="job_status" dataType="string" caption="Status" cellRender={this.jobStatusRenderMobile} width="73"/>
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
      </div>
      </>
    );
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh(true)
      .then(function() {
          //console.log('Refreshed');
      })
      .catch(function(error) {
          //console.log(error);
      });
  }

}

export default TPJobsTable;
