import React from 'react';
import PieChart, {
  Legend,
  Series,
  Label,
  Connector,
  Title,
  Subtitle,
  Font,
} from 'devextreme-react/pie-chart';

const TPTotalUsageChart = props => {
  const customizeText = (pointInfo) => {
    if (pointInfo.argument === "Remaining") {
      return pointInfo.value + " pages remaining";
    }
    return pointInfo.value + " pages processed";
  }

  const customizePoint = (pointInfo) => {
    if (pointInfo.argument === "Remaining") {
      return { color: "rgba(0,0,0,0.2)" };
    }
    return { color: "rgb(209, 161, 209)" };
  };

    return (
      <>
        <PieChart
          id="pie"
          startAngle={90}
          type="doughnut"
          palette="Violet"
          dataSource={props.totalUsageData}
          customizePoint={customizePoint}>
        >
          <Title text="Total Usage">
            <Subtitle text={"Your plan includes up to "+props.maxAllowedUsage.toString()+" pages"}>
              <Font weight="100" />
            </Subtitle>
          </Title>
          <Series argumentField="label" valueField="value" color="blue">
            <Label visible={true} position="columns" customizeText={customizeText}>
              <Connector visible={true} />
            </Label>
          </Series>
          <Legend
            visible={false}
          />
        </PieChart>
      </>
    );
};

export default TPTotalUsageChart
