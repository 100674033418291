import React, { useState, useEffect, useCallback } from 'react';
import TPUsageChart from '../../components/tp-usage-chart/TPUsageChart'
import TPTotalUsageChart from '../../components/tp-total-usage-chart/TPTotalUsageChart'
import { Calendar } from 'devextreme-react/calendar';
import {getUsage} from '../../api/tpAPI'
import { useAuth } from '../../contexts/auth';

export default function Usage() {
  const { user, refreshSession } = useAuth();

  const [year, setYear] = useState((new Date()).getUTCFullYear());
  const [month, setMonth] = useState((new Date()).getUTCMonth()+1);
  const [usageData, setUsageData] = useState([]);
  const [totalUsageData, setTotalUsageData] = useState([]);
  const [maxAllowedUsage, setMaxAllowedUsage] = useState(0);
  const [calendarValue, setCalendarValue] = useState((new Date()));

  const numDays = (y, m) => new Date(y, m, 0).getDate();

  const onCalendarValueChanged = (e) => {
    console.log(e.value);
    setCalendarValue(e.value);
    setYear(e.value.getUTCFullYear());
    setMonth(e.value.getUTCMonth()+1);
    getUsage(user, e.value.getUTCFullYear().toString(), (e.value.getUTCMonth()+1).toString(), '', onGetResultSuccess, onError);
  }

  const onError = (error) => {
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }

  const onGetResultSuccess = useCallback((result) => {
    const res = []
    for (let i=1; i<=numDays(year, month); i++) {
      res.push({day: (new Date(year, month, 0)).toLocaleString('default', { month: 'short' })+'-'+i.toString(), value: 0})
    }
    let total_count = 0;
    const data = result.usage;
    for (let i=0; i<data.length; i++) {
      const dd = new Date(data[i].created_at);
      res[dd.getDate()].value = res[dd.getDate()].value + data[i].page_count;
      total_count = total_count + data[i].page_count;
    }
    setUsageData(res);
    setMaxAllowedUsage(result.pages_included);
    setTotalUsageData([{label:'Used', value:total_count}, {label:'Remaining', value:maxAllowedUsage-total_count}])
  }, [maxAllowedUsage, month, year])

  useEffect(() => {
    if (user.signInUserSession) {
      getUsage(user, year.toString(), month.toString(), '', onGetResultSuccess, onError);
    } else {
      refreshSession();
    }
  }, [user, year, month, onGetResultSuccess]);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Usage</h2>

      <div className="d-none d-lg-flex flex-row w-100 justify-content-between">
        <div className={'me-3'} style={{width: "69%"}}>
          <div className={'dx-card w-100 p-3'}>
            <TPUsageChart
              data={usageData}
            />
          </div>
        </div>
        <div className={'d-flex flex-column justify-content-between' } style={{width: "29%"}}>
          <div className={'dx-card w-100'}>
            <Calendar
              width="100%"
              maxZoomLevel={'year'}
              minZoomLevel={'decade'}
              zoomLevel={'year'}
              value={calendarValue}
              onValueChanged={onCalendarValueChanged}
              min={(new Date(2023,10,1))}
              max={(new Date())}
            >
            </Calendar>
          </div>
          <div className={'dx-card w-100 mt-2'} style={{minWidth: "260px"}}>
            <TPTotalUsageChart
              totalUsageData={totalUsageData}
              maxAllowedUsage={maxAllowedUsage}
            />
          </div>
        </div>
      </div>

      <div className="d-none d-md-flex d-lg-none flex-column w-100 justify-content-between">
        <div className={'d-flex flex-row justify-content-between' } >
          <div className={'dx-card me-3 w-100'} >
            <Calendar
              width="100%"
              maxZoomLevel={'year'}
              minZoomLevel={'decade'}
              zoomLevel={'year'}
              value={calendarValue}
              onValueChanged={onCalendarValueChanged}
              min={(new Date(2023,10,1))}
              max={(new Date())}
            >
            </Calendar>
          </div>
          <div className={'dx-card w-100'}>
            <TPTotalUsageChart
              totalUsageData={totalUsageData}
              maxAllowedUsage={maxAllowedUsage}
            />
          </div>
        </div>
        <div className={'pt-2 me-3'} style={{width: "100%", minHeight: "400px", maxHeight: "400px"}}>
          <div className={'h-100 dx-card p-3' } style={{width: "100%", minHeight: "400px", maxHeight: "400px"}}>
            <TPUsageChart
              data={usageData}
            />
          </div>
        </div>
      </div>

      <div className="d-flex d-md-none flex-column w-100 justify-content-between">
        <div className={'dx-card me-3 w-100'}>
          <Calendar
            width="100%"
            maxZoomLevel={'year'}
            minZoomLevel={'decade'}
            zoomLevel={'year'}
            value={calendarValue}
            onValueChanged={onCalendarValueChanged}
            min={(new Date(2023,10,1))}
            max={(new Date())}
          >
          </Calendar>
        </div>
        <div className={'dx-card w-100 mt-3'}>
          <TPTotalUsageChart
            totalUsageData={totalUsageData}
            maxAllowedUsage={maxAllowedUsage}
          />
        </div>
        <div className={'px-2 dx-card w-100 mt-3'}>
          <TPUsageChart
            data={usageData}
          />
        </div>
      </div>

    </React.Fragment>
)}
