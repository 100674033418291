import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  HeadingLevel,
  LevelFormat,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun
} from "docx";

class HTMLDocGenerator {

  create(html) {
    console.log(html);
    const numbering_configs = [];
    const sections = [];
    for (let i=0; i<html.length; i++) {
      let children = html[i].props.children;

      if (html[i].type === "p") {
        var p_text = ""
        for (let j=0; j<children.length; j++) {
          if (children[j].type === "br") {
            p_text = p_text + "\n"
          }
          if (children[j].type === undefined) {
            p_text = p_text + children[j]
          }
        }
        sections.push(new Paragraph({text: p_text}));

      }

      if (html[i].type === "ol") {
        var p_text = ""
        for (let j=0; j<children.length; j++) {
          if (children[j].type === "li") {
            p_text = p_text + children[j].props.children[0];
          }
          sections.push(new Paragraph(
            {
              text: p_text,
              numbering: {
                  reference: html[i].type+html[i].key,
                  level: 0,
              },
            }
          ));
        }
        numbering_configs.push({
            reference: html[i].type+html[i].key,
            levels: [
                {
                    level: 0,
                    format: LevelFormat.DECIMAL,
                    text: "%1.",
                    alignment: AlignmentType.START,
                    style: {
                        paragraph: {
                            indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                        },
                    },
                },
            ],
        });

      }

    }
    const document = new Document(
      {
        numbering: {
            config: numbering_configs,
        },
        sections: [{children: sections}]
      }
    );
    return document;
  }
};

export default HTMLDocGenerator;
