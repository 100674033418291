const appInfo = {
    title: 'QuikSum',
    company: 'Woodlands Digital Technologies, LLC',
    company_website: 'https://www.wdttx.com',
    company_address: '7 Columnberry Ct, The Woodlands, TX 77384',
    company_email: 'contact@wdttx.com',
    app_website: 'https://www.QuikSum.com',
    app_website_name: 'QuikSum.com'
};
export default appInfo;
