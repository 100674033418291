import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './home.scss';
import { TextArea } from 'devextreme-react/text-area';
import { Button } from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { addFeedback } from '../../api/tpAPI'
import { useAuth } from '../../contexts/auth';

export default function Home() {
  var { user, refreshSession } = useAuth();
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFeedbackChange = (e) => {
    setFeedback(e.value);
  }

  const onFeedbackSubmit = (e) => {
    if (feedback.length < 5) {
      notify("Please add your feedback before submiting.", 'error', 5000);
      return
    }
    const data = {text: feedback}
    setLoading(true);
    addFeedback(user, data,
      () => {
        setLoading(false);
        notify("Thank you for your feedback!", 'success', 5000);
      },
      (error) => {
        setLoading(false);
        if (error.status === 401) {
          refreshSession();
        } else {
          console.log(error);
        }
        notify("An error has occured. Refresh your page and try again.", 'error', 5000);
      }
    );
  }

  const onGetStarted = (e) => {
    navigate('/tp');
  }

  useEffect(() => {
    if (!user.signInUserSession) {
      refreshSession();
    }

  }, [user && user.signInUserSession]);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Home</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p className="accent-text">Thank you for being an early user of QuikSum.</p>
          <Button className="px-5 mb-3" type="default" size="lg" onClick={onGetStarted}>
             Get Started
          </Button>
          <p>With your feedback we aim to build a tool that will allow you to generate different kind of documents based on a source document:</p>
          <ul>
            <li>Talking points for a meeting or presentation</li>
            <li>Summarization for a quick understanding of the content of the document</li>
            <li>Press release</li>
            <li>Tweets or short social media messages</li>
          </ul>
          <br/><br/>
          <p>This is a prototype and there might be sections of the app that are not working as expected.</p>
          <p>Here is a list of known issues and limitations:</p>
          <ul>
            <li className="accent-text-color">The application is not yet mobile friendly. For a better experience please try it on a laptop, desktop or tablet.</li>
            <li>The profile page is just a placeholder for now.</li>
            <li>You can upload only .pdf or .docx (Word Documents) </li>
          </ul>
          <h4 className="mx-0"></h4>
          <div className="form">

            <div className="dx-fieldset">
              <div className="dx-fieldset-header">Please tell us about your problems or ideas here</div>

              <TextArea
                  minHeight={100}
                  maxHeight={400}
                  autoResizeEnabled={true}
                  label="Your feedback"
                  value={feedback}
                  onValueChanged={handleFeedbackChange}
                  disabled={loading}
              />

              <div className="dx-field w-100 mt-2 d-flex justify-content-center">
                {loading && <LoadIndicator id="small-indicator" height={20} width={20} />}
                <Button className="px-5" type="normal" size="lg" disabled={loading} onClick={onFeedbackSubmit}>
                   Submit
                </Button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </React.Fragment>
)}
