import React, { useState, useEffect, useCallback, useRef } from 'react';
import TPJobsTable from '../../components/tp-jobs-table/TPJobsTable'
import TPResults from '../../components/tp-results/TPResults'
import Accordion from 'devextreme-react/accordion';
import TPSavedResultsEditor from '../../components/tp-saved-results-editor/TPSavedResultsEditor'
import {getJobs} from '../../api/tpAPI'
import {getResultByKey} from '../../api/s3'
import { useAuth } from '../../contexts/auth';


export default function Jobs() {
  const { user, refreshSession } = useAuth();
  const tableRef = useRef();
  const [jobsData, setJobsData] = useState([]);
  const [generatedTP, setGeneratedTP] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedJobResults, setSelectedJobResults] = useState(null);
  const [refreshingJobs, setRefreshingJobs] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(true);

  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onGetResultSuccess = useCallback((data) => {
    console.log(data);
    if (!data.results) {
      setGeneratedTP([]);
      setSelectedJobResults(null);
      return;
    }
    const res=[];
    for (let i = 0; i<data.results.length; i++) {
      res.push({id:i, text:data.results[i]});
    }
    setSelectedJobResults(data);
    setGeneratedTP(res);
  }, [])

  const onGetResultsSuccess = useCallback((data) => {
    const res = [];
    var rn = false;
    for (let i = 0; i<data.length; i++) {
      data[i].key=data[i].results_file;
      res.push(data[i]);
      if (data[i].job_status === "In Progress") {
        rn = true;
      }
    }
    setJobsData(res);
    setRefreshNeeded(rn);

    if ((tableRef.current) && (rn)) {
      setTimeout(function() {
        getJobs(user, null, null, null, onGetResultsSuccess, onError);
      }, 2000);
    } else {
      setRefreshingJobs(false);
    }

  }, [])

  const onRowClicked = (e) => {
    console.log(e);
    if (e.rowType === 'data') {
      setSelectedJob(e.data);
      if (e.data.job_status === 'Completed') {
        getResultByKey(e.data.results_file, onGetResultSuccess, onError);
      }
    } else {
      setGeneratedTP([]);
    }
  }

  useEffect(() => {
    if (user.signInUserSession) {
      getJobs(user, null, null, null, onGetResultsSuccess, onError);
      //getUsage(user, year.toString(), month.toString(), '', onGetResultSuccess, onError);
    } else {
      refreshSession();
    }
  }, [user, user&&user.signInUserSession, onGetResultsSuccess]);

  /*
  multiple={multiple}
  animationDuration={animationDuration}
  selectedItems={selectedItems}
  onSelectionChanged={this.selectionChanged}
  itemTitleRender={CustomTitle}
  itemRender={CustomItem}
  id="accordion-container"
  */

  const onSavedTPChanged = (data) => {
    console.log(data);
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Jobs</h2>
      <TPJobsTable ref={tableRef} dataSource={jobsData} onRowClicked={onRowClicked}/>
      <h3 className="mx-3">Job Results</h3>


      { generatedTP.length !==0 && selectedJob.job_status === "Completed" &&
        <div className="dx-card">
          <TPSavedResultsEditor savedTP={generatedTP} jobData={selectedJob} onChange={onSavedTPChanged}/>
        </div>
      }


      {false && <TPResults generatedTP={generatedTP} jobData={selectedJob}/>}

      {selectedJobResults && <h2 className={'content-block'}>Summarization results</h2>}
      {selectedJobResults &&<Accordion
          dataSource={selectedJobResults.summary_results}
          collapsible={true}
        />}

    </React.Fragment>
)}
