export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'fas fa-home'
  },
  {
    text: 'Generate',
    path: '/tp',
    icon: 'fas fa-feather-alt'
  },
  {
    text: 'Jobs',
    path: '/jobs',
    icon: 'fas fa-tasks'
  },
  {
    text: 'Usage',
    path: '/usage',
    icon: 'fas fa-chart-bar'
  },
  {
    text: 'Billing',
    path: '/billing',
    icon: 'far fa-credit-card'
  },
  {
    text: 'Legal',
    icon: 'fas fa-info-circle',
    items: [
      {
        text: 'Terms of Service',
        path: '/legal/tos'
      },
      {
        text: 'Privacy Policy',
        path: '/legal/pp'
      }
    ]
  }
  ];
